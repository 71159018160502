export const Footer = () => {
  return (
    <footer className="footer fr">
      <div className="">
        <h2>About</h2>
        <p>
          MoneyCoach was founded with ❤️ by{' '}
          <a href="https://www.linkedin.com/in/paul-zakin-56aa1b80">Paul</a> and{' '}
          <a href="https://www.linkedin.com/in/marika-kachman-74b8b8132">
            Mika
          </a>{' '}
          to make financial education engaging and free!
        </p>
      </div>
      <div className="legal">
        <h2>Legal</h2>
        <p>
          By using this site, you agree to our{' '}
          <a href={'/terms-of-use.pdf'}>Terms of Use</a> &{' '}
          <a href={'/privacy-policy.pdf'}>Privacy Policy</a>
        </p>
      </div>
    </footer>
  )
}
