import type { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'

import { comments } from '../components/Comments'
import { Layout } from '../components/Layout'

const videoButton = (
  <Link href="/videos" passHref>
    <button type="button" className="cta button m10">
      Watch a video
    </button>
  </Link>
)

const productButton = (
  <Link href="/recommendations" passHref>
    <button type="button" className="cta button m10">
      Get a product
    </button>
  </Link>
)

const Home: NextPage = () => {
  return (
    <Layout>
      <Head>
        <title>Home</title>
        <meta
          name="description"
          content="MoneyCoach teaches you how to manage your finances for free through engaging, animated videos!"
        />
      </Head>
      <div className="home">
        <div className="header big">World-class financial education.</div>
        <div className="header big mt20 accented">Free, forever.</div>

        <p className="subtitle">
          MoneyCoach is a free curriculum based entirely on short,{' '}
          <strong>animated videos</strong>, the most effective way to learn{' '}
          <a
            target="blank"
            rel="noopener"
            href="https://hilt.harvard.edu/wp-content/uploads/2018/08/HILT_SpeakerSeries_Mayerslides.pdf"
          >
            according to researchers
          </a>
          . Who said education couldn{`'`}t be effective <strong>and</strong>{' '}
          fun?
        </p>

        <div className="frc center wrap">
          {videoButton}
          {productButton}
        </div>

        <div className="divider center" />

        <div className="header">Is it really free?</div>
        <p className="subtitle">
          Yup! All our videos are hosted on Youtube and our costs are less than
          $1,000 per year. Plus, our costs are covered by the revenue we get
          from Youtube advertising. But, even when it didn{`'`}t, we covered it
          out of our own pockets. We consider MoneyCoach a public service and
          intend to fund it into perpetuity.
        </p>

        <div className="header">Can I use the videos?</div>
        <p className="subtitle">
          In most cases, yes! The only thing we explicitly forbid you from doing
          is embedding our videos in your product or website{' '}
          <strong>
            unless the product is entirely free and designed for education
          </strong>
          . Otherwise, as long as you provide a link back to our website, feel
          free to link or embed our Youtube videos whenever and wherever. You
          don{`'`}t even need to ask for permission!
        </p>
        <div className="header">Do you recommend products?</div>
        <p className="subtitle">
          Yes! Unlike many other educational tools, we believe firmly that
          financial education without product recommendations is silly! We may
          tell you to open a bank account but if we don{`'`}t tell you which
          one, that limits how actionable our content can be.
        </p>
        <p className="subtitle">
          However, we are <strong>not</strong> paid for anything we recommend
          our website and we intend to keep it that way. To put it bluntly, we
          are not going to compromise our mission (free education) by
          recommending a worse product just to get paid. Not only is just the{' '}
          <strong>wrong</strong> thing to do, we just don{`'`}t need to. Our
          costs are extremely low and covered by Youtube advertising!
        </p>
        <div className="header">Do you have stuff for teachers?</div>
        <p className="subtitle">
          We sure do! Every video in the MoneyCoach curriculum has an
          associated, six-question quiz. You can download all of our quizzes{' '}
          <strong>
            <a href="/quizzes.pdf">here</a>.
          </strong>
        </p>

        <div className="header">What students say</div>
        <div className="comments fcc">
          {comments.map((comment) => (
            <div key={comment} className="comment">
              {comment}
            </div>
          ))}
        </div>
        <div className="divider center" />

        <div className="header">Ready to start?</div>
        <div className="frc center wrap mb75">
          {videoButton}
          {productButton}
        </div>
      </div>
    </Layout>
  )
}

export default Home
